@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

:root {
  --UNLP_COLOR: #02545e;
  --GUARANI_COLOR: #0088cc;
}

#root > * {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

.react-chatbot-kit-chat-input-form {
  margin: 0;
}

div.react-chatbot-kit-chat-input-container > form > input {
  font-size: 14px;
  font-family: 'Roboto', Helvetica, sans-serif;
}

.react-chatbot-kit-chat-btn-send {
  background-color: var(--GUARANI_COLOR);
}

.react-chatbot-kit-chat-inner-container {
  position: relative;
  overflow-x: hidden;
}

.react-chatbot-kit-chat-container,
.first-button-container {
  font-family: 'Roboto', Helvetica, sans-serif;
  position: fixed;
  bottom: 65px;
  right: 1em;
  z-index: 999;
}

.react-chatbot-kit-chat-container {
  width: 300px;
  bottom: 30px;
}

.react-chatbot-kit-chat-message-container {
  overflow-x: hidden;
}

.react-chatbot-kit-chat-bot-message {
  word-wrap: break-word;
  color: #585858;
  background-color: #f1f1f1;
}

#chatbot-loader {
  fill: #585858;
}

.react-chatbot-kit-user-chat-message-arrow {
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid var(--UNLP_COLOR);
  position: absolute;
  right: -7px;
  top: 13px;
}

.react-chatbot-kit-user-avatar-container {
  background-color: var(--UNLP_COLOR);
}

.react-chatbot-kit-chat-bot-message-arrow {
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid var(--GUARANI_COLOR);
  position: absolute;
  left: -7px;
  top: 13px;
}

.react-chatbot-kit-chat-bot-message > span {
  font-size: 14px;
  line-height: 1em !important;
  margin-bottom: 5px;
}

.react-chatbot-kit-chat-bot-message > span > p {
  line-height: 1em !important;
  margin-bottom: 0 !important;
  font-size: 14px;
}

.react-chatbot-kit-chat-bot-message > span > p > a {
  text-decoration: underline;
  color: #333 !important;
  transition: all 0.1s;
}

.react-chatbot-kit-chat-bot-message > span > p > a:hover {
  opacity: 0.8;
}
.react-chatbot-kit-chat-bot-message > span > p > a:visited {
  opacity: 0.8;
  color: #333;
}

.react-chatbot-kit-user-chat-message {
  font-size: 14px;
}

.first-button-container {
  border-radius: 8px;
  padding: 1em;
  background: transparent;
  font-size: 14px;
  background-color: var(--GUARANI_COLOR);
  color: white;
  cursor: pointer;
  outline: none;
  border: none;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  gap: 8px;
}

.first-button-container:hover {
  opacity: 0.8;
  transform: scale(1.1);
}
