.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding: 10px;
  background-color: var(--GUARANI_COLOR);
}

.header-container > p {
  font-size: inherit;
  margin-bottom: 0 !important;
  color: #fff;
}

.header-close-button-container {
  margin-right: 5px;
  height: 20px;
  width: 20px;
}

.header-close-button-container button {
  margin-left: 0;
  outline: none;
  border: none;
  background-color: transparent;
  color: #fff;
  cursor: pointer;
  font-size: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s;
}

.header-close-button-container button:hover {
  color: red;
}

.header-close-button-container button svg {
  width: 100%;
  height: 100%;
}
